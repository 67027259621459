:root {
  --adm-radius-s: 4px;
  --adm-radius-m: 8px;
  --adm-radius-l: 12px;
  --adm-font-size-1: 9px;
  --adm-font-size-2: 10px;
  --adm-font-size-3: 11px;
  --adm-font-size-4: 12px;
  --adm-font-size-5: 13px;
  --adm-font-size-6: 14px;
  --adm-font-size-7: 15px;
  --adm-font-size-8: 16px;
  --adm-font-size-9: 17px;
  --adm-font-size-10: 18px;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-text: #333333;
  --adm-color-text-secondary: #666666;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-color-border: #eeeeee;
  --adm-color-background: #ffffff;
  --adm-color-white: #ffffff;
  --adm-color-box: #f5f5f5;
  --adm-color-text-light-solid: var(--adm-color-white);
  --adm-color-text-dark-solid: #000000;
  --adm-color-fill-content: var(--adm-color-box);
  --adm-font-size-main: var(--adm-font-size-5);
  --adm-font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue',
    helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui',
    'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  --adm-border-color: var(--adm-color-border);
}
html[data-prefers-color-scheme='dark'] {
  --adm-color-primary: #3086ff;
  --adm-color-success: #34b368;
  --adm-color-warning: #ffa930;
  --adm-color-danger: #ff4a58;
  --adm-color-text: #e6e6e6;
  --adm-color-text-secondary: #b3b3b3;
  --adm-color-weak: #808080;
  --adm-color-light: #4d4d4d;
  --adm-color-border: #2b2b2b;
  --adm-color-box: #0a0a0a;
  --adm-color-background: #1a1a1a;
  --adm-border-color: var(--adm-color-border);
}
:root {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  color: #333333;
  color: var(--adm-color-text);
  font-size: 13px;
  font-size: var(--adm-font-size-main);
  font-family: -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, segoe ui, arial, roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
  font-family: var(--adm-font-family);
}
a,
button {
  cursor: pointer;
}
a {
  color: #1677ff;
  color: var(--adm-color-primary);
  transition: opacity ease-in-out 0.2s;
}
a:active {
  opacity: 0.8;
}
.adm-plain-anchor {
  color: unset;
  transition: none;
}
.adm-plain-anchor:active {
  opacity: unset;
}
body.adm-overflow-hidden {
  overflow: hidden !important;
}
div.adm-px-tester {
  --size: 1;
  height: calc(var(--size) / 2 * 2px);
  width: 0;
  position: fixed;
  right: -100vw;
  bottom: -100vh;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
